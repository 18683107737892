<template>
  <dashboard-layout>
    <loader-animation v-if="isLoading" />
    <div class="w-full p-5" v-if="!isLoading">
      <div class="p-4 flex">
        <h1 class="text-3xl">
          Minhas Oportunidades
        </h1>
      </div>
      <div class="p-4">
        <div class="flex mb-4 justify-between">
          <router-link
            class="bg-green-1000 text-white px-6 py-3 rounded rounded-md focus:outline-none"
            :to="{ name: 'dashboard' }"
          >
            Voltar
          </router-link>
        </div>

        <v-client-table
          ref="table"
          :data="vacancies"
          :columns="columns"
          :options="options"
        >
          <div class="flex justify-center" slot="actions" slot-scope="{ row }">
            <router-link
              class="flex p-1 border-2 border-green-1002 w-max rounded-lg mr-4"
              :to="{
                name: 'vacancy-description',
                params: { id: row.id }
              }"
              v-tooltip="{ content: 'Ver Vaga', placement: 'right' }"
            >
              Ver Vaga
            </router-link>
          </div>
        </v-client-table>
      </div>
    </div>
  </dashboard-layout>
</template>

<script>
import DashboardLayout from '../../../../layouts/DashboardLayout';
import { mapGetters } from 'vuex';
import axios from '@/utils/axios';
import LoaderAnimation from '../../../../components/LoaderAnimation.vue';

export default {
  name: 'MyVacancies',

  title() {
    return `${process.env.VUE_APP_NAME} | Minhas Oportunidades`;
  },

  components: {
    DashboardLayout,
    LoaderAnimation
  },

  data() {
    return {
      isLoading: false,
      vacancies: [],
      columns: [
        'id',
        'office',
        'salary',
        'company',
        'validity_from',
        'validity_to',
        'statusVacancy',
        'status',
        'actions'
      ],
      options: {
        sortable: true,
        perPage: 10,
        perPageValues: [10, 15, 20, 25, 50],
        headings: {
          office: 'Vaga',
          salary: 'Salário',
          company: 'Empresa',
          validity_from: 'Inc. da Inscrição',
          validity_to: 'Fim da Inscrição',
          statusVacancy: 'Status da Vaga',
          actions: 'Ações'
        },
        hiddenColumns: ['id'],
        sortIcon: {
          is: 'glyphicon-sort',
          base: 'glyphicon',
          up: 'glyphicon-chevron-up',
          down: 'glyphicon-chevron-down'
        },
        texts: {
          first: 'Primeiro',
          last: 'Último',
          filter: 'Filtrar:',
          filterBy: 'Filtrar por {column}',
          filterPlaceholder: 'Pesquise aqui...',
          count:
            '{from} - {to} de {count} oportunidades|{count} oportunidades|Uma vaga',
          limit: 'Registros por página:',
          page: 'Página:',
          noResults: 'Nenhuma vaga encontrada.',
          columns: 'Colunas'
        },
        pagination: {
          chunk: 5
        }
      }
    };
  },

  computed: {
    ...mapGetters('auth', {
      user: 'user',
      isAvailable: 'isAvailable'
    })
  },

  methods: {
    async fetchMyVacancies() {
      this.isLoading = true;
      if (this.user.role.name === 'Instructor') {
        await axios
          .get(`/api/vagas/${this.user.id}/minhas-vagas-instructor/`)
          .then(({ data }) => {
            data.data.forEach(vacancy => {
              this.vacancies.push(vacancy);
            });
            this.isLoading = false;
          });
      } else {
        await axios
          .get(`/api/vagas/${this.user.participant_id}/minhas-vagas/`)
          .then(({ data }) => {
            data.data.forEach(vacancy => {
              this.vacancies.push(vacancy);
            });
            this.isLoading = false;
          });
      }
    }
  },
  created: function() {
    this.fetchMyVacancies();
  }
};
</script>

<style scoped></style>
